import {
    ComponentClass,
    ComponentProps,
    ComponentType,
    ForwardRefExoticComponent,
    FunctionComponent,
    PropsWithoutRef,
    RefAttributes,
    forwardRef,
} from 'react';

import { StringKeysOf } from 'gs-uitk-type-utils';
import {
    CssClassDefinitions,
    CssClassDefinitionsObject,
    CssClasses,
    CssFactoryPropsType,
    cx,
    StyleSheet,
} from '@gs-ux-uitoolkit-common/style';
import { useStyleSheet } from './use-style-sheet';
import { StyledOptions } from './styled-options';
import { getComponentName } from './util/get-component-name';
import { filterProps } from './util/filter-props';

/**
 * Styled Components API for CSS-in-JS styling which resembles the "styled-components"
 * npm package, and allows developers to easily style the elements of Toolkit
 * components.
 *
 * Example usage to style a Component (note: the Component must accept the `classes` prop):
 *
 *     import { Alert } from '@gs-ux-uitoolkit-react/alert';
 *
 *     const MyAlert = styledClasses(Alert, {
 *         // root (outer) element of the alert
 *         root: {
 *             border: '1px solid blue'
 *         },
 *
 *         // the dismiss button element
 *         dismissButton: {
 *             color: 'red'
 *         },
 *
 *         // icon element
 *         icon: {
 *             padding: '5px'
 *         }
 *     });
 *
 *     const root = createRoot(document.body);
 *     root.render(<MyAlert />);
 */
// **Signature 1**: styledClasses(SomeClassComponent, (props: CssProps) => ({ root: { color: props.color }}))
// NOTE: For some reason had to define the classDefinitionsFactory function signature before the object literal one
export function styledClasses<
    CssProps extends object, // CssProps is the first type arg in case Partial Type Argument Inference is ever implemented (https://github.com/microsoft/TypeScript/issues/26242)
    WrappedComponentClass extends ComponentClass<any>,
>(
    Component: WrappedComponentClass,
    classDefinitionsFactory: (
        props: CssProps
    ) => Partial<CssClassDefinitionsObject<ClassesPropKeys<WrappedComponentClass>>>,
    options?: StyledOptions
): ForwardRefExoticComponent<
    PropsWithoutRef<ComponentProps<WrappedComponentClass>> &
        CssProps &
        RefAttributes<InstanceType<WrappedComponentClass>>
>;

// **Signature 2**: styledClasses(SomeClassComponent, { root: { color: 'blue' }})
export function styledClasses<WrappedComponentClass extends ComponentClass<any>>(
    Component: WrappedComponentClass,
    classDefinitions: Partial<CssClassDefinitionsObject<ClassesPropKeys<WrappedComponentClass>>>,
    options?: StyledOptions
): ForwardRefExoticComponent<
    PropsWithoutRef<ComponentProps<WrappedComponentClass>> &
        RefAttributes<InstanceType<WrappedComponentClass>>
>;

// Ref forwarding Function Components

// **Signature 3**: styledClasses(SomeForwardedRefExoticComponent, (props: CssProps) => ({ root: { color: props.color }}))
// NOTE: For some reason had to define the classDefinitionsFactory function signature before the object literal one
export function styledClasses<
    CssProps extends object, // CssProps is the first type arg in case Partial Type Argument Inference is ever implemented (https://github.com/microsoft/TypeScript/issues/26242)
    WrappedFunctionComponent extends ForwardRefExoticComponent<any>,
>(
    Component: WrappedFunctionComponent,
    classDefinitionsFactory: (
        props: CssProps
    ) => Partial<CssClassDefinitionsObject<ClassesPropKeys<WrappedFunctionComponent>>>,
    options?: StyledOptions
): ForwardRefExoticComponent<ComponentProps<WrappedFunctionComponent> & CssProps>;

// **Signature 4**: styledClasses(SomeForwardedRefExoticComponent, { root: { color: 'blue' }})
export function styledClasses<WrappedFunctionComponent extends ForwardRefExoticComponent<any>>(
    Component: WrappedFunctionComponent,
    classDefinitions: Partial<CssClassDefinitionsObject<ClassesPropKeys<WrappedFunctionComponent>>>,
    options?: StyledOptions
): ForwardRefExoticComponent<ComponentProps<WrappedFunctionComponent>>;

// Non-ref-forwarding Function Components

// **Signature 5**: styledClasses(SomeFunctionComponent, (props: CssProps) => ({ root: { color: props.color }}))
// - Note: doesn't accept a 'ref' attribute
export function styledClasses<
    CssProps extends object, // CssProps is the first type arg in case Partial Type Argument Inference is ever implemented (https://github.com/microsoft/TypeScript/issues/26242)
    WrappedFunctionComponent extends FunctionComponent<any>,
>(
    Component: WrappedFunctionComponent,
    classDefinitionsFactory: (
        props: CssProps
    ) => Partial<CssClassDefinitionsObject<ClassesPropKeys<WrappedFunctionComponent>>>,
    options?: StyledOptions
): FunctionComponent<ComponentProps<WrappedFunctionComponent> & CssProps>;

// **Signature 6**: styledClasses(SomeFunctionComponent, { root: { color: 'blue' }})
// - Note: doesn't accept a 'ref' attribute
export function styledClasses<WrappedFunctionComponent extends FunctionComponent<any>>(
    Component: WrappedFunctionComponent,
    classDefinitions: Partial<CssClassDefinitionsObject<ClassesPropKeys<WrappedFunctionComponent>>>,
    options?: StyledOptions
): FunctionComponent<ComponentProps<WrappedFunctionComponent>>;

// Implementation signature
export function styledClasses(
    Component: ComponentType<unknown> | ForwardRefExoticComponent<unknown>,
    classDefinitions: Partial<CssClassDefinitions<string, CssFactoryPropsType>>,
    { label, shouldForwardProp }: StyledOptions = {}
): ForwardRefExoticComponent<any> {
    const styleSheetName = label || getComponentName(Component) || 'styled';
    const styleSheet = new StyleSheet(
        styleSheetName,
        classDefinitions as CssClassDefinitions<string, CssFactoryPropsType>
    );

    const StyledClasses: ForwardRefExoticComponent<any> = forwardRef((props, ref) => {
        const classes = useStyleSheet(styleSheet, props);
        const propsClasses = props.classes || {};

        const classNames = [...Object.keys(classes), ...Object.keys(propsClasses)];
        const dedupedClassNames = Array.from(new Set(classNames)); // use Set to remove duplicates

        // Create the final `classes` prop by combining the styledClasses() CSS
        // classes with any user-provided `props.classes`
        const finalClasses: CssClasses<string> = {};
        for (const className of dedupedClassNames) {
            finalClasses[className] = cx(classes[className], propsClasses[className]);
        }
        const propsWithRef = { ...props, ref };
        const filteredProps = shouldForwardProp
            ? filterProps(propsWithRef, shouldForwardProp)
            : propsWithRef;
        const finalProps = {
            ...filteredProps,
            classes: finalClasses,
        };
        return <Component {...finalProps}>{props.children}</Component>;
    });
    StyledClasses.displayName = 'StyledClasses';

    return StyledClasses;
}

/**
 * Alias for all 3 types of React components: ComponentClass, FunctionComponent,
 * and ForwardRefExoticComponent.
 */
type ReactComponent<P = any> = ComponentType<P> | ForwardRefExoticComponent<P>;

/**
 * Retrieves the type of the 'classes' prop of the given ReactComponent (which
 * can be a ComponentClass, FunctionComponent, or ForwardRefExoticComponent).
 */
type ClassesPropOf<TargetComponent extends ReactComponent> = NonNullable<
    ComponentProps<TargetComponent>['classes']
>;

/**
 * Retrieves the string union of the keys of the 'classes' prop for the given
 * ReactComponent.
 */
type ClassesPropKeys<TargetComponent extends ReactComponent> = StringKeysOf<
    ClassesPropOf<TargetComponent>
>;
